.card-button {
  position: relative;
  height: 40px;
  border: 1px solid #bcd2e8;
  color: #bcd2e8;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin-right: 10px;
  transition: 0.3s;
  width: 44px;
  overflow: hidden;
}
.card-button:last-child {
  margin-right: 0px;
}
.card-button:hover {
  background-color: #4f7286;
  width: 100px;
}

.card-button-text-hidden {
  width: 0px;
  opacity: 0;
}

.card-button-text {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  font-size: 16px;
  margin-right: 10px;
}

.button-icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  fill: #bcd2e8;
}