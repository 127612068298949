.nav {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.nav-header {
  font-size: 32px;
}