.section {
  padding-top: 140px;
  padding-left: 20px;
  min-height: 100vh;
}

.section-title {
  font-size: 30px;
  margin-bottom: 30px;
}

.card-holder {
  display: flex;
  flex-wrap: wrap;
}