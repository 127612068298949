.project-card {
  width: 480px;
  background-color: #3c5766;
  position: relative;
  box-shadow: 0px 10px 23px -10px rgba(0, 0, 0, 0.52);
  margin-right: 30px;
  margin-bottom: 30px;
  border: 1px solid #bcd2e8;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.project-card-title {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #162229;
  border-bottom: 1px solid #bcd2e8;
}
.project-card-title a {
  color: #bcd2e8;
}

.project-card-logo {
  height: 20px;
  margin-right: 10px;
  transition: 1s;
}
.project-card-logo:hover {
  transform: rotateY(360deg);
}

.project-card-image-holder {
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.project-card-tagline-and-buttons {
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 40px;
  margin-bottom: 20px;
  padding-right: 10px;
  overflow: hidden;
}

.project-card-button-holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 470px) {
  .project-card-button-holder {
    width: 100%;
    margin-top: 10px;
  }
}

.project-card-description {
  border-top: 1px solid #bcd2e8;
  padding: 10px;
}
.project-card-description a {
  color: #bcd2e8;
  text-decoration: underline;
}