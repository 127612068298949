.offset-zoomer {
  width: 100%;
  height: 100%;
  display: block;
  cursor: crosshair;
  touch-action: none;
}

.zoomable-image {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
  -o-object-position: center;
     object-position: center;
  transition: 0.3s;
  pointer-events: none;
}