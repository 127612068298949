.header {
  font-size: 50px;
  text-shadow: 2px 2px 15px black;
  position: relative;
  height: 100vh;
}
@media screen and (max-width: 750px) {
  .header {
    font-size: 30px;
  }
}
@media screen and (max-width: 460px) {
  .header {
    font-size: 20px;
  }
}

.header-text-holder {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}