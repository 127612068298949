.appearing-text-holder {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.appearing-text {
  background-color: #162229;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  font-size: 17px;
  --notchTopLeft: 0%;
  --notchTopRight: 0%;
  --notchBottomLeft: 10px;
  --notchBottomRight: 0px;
  -webkit-clip-path: polygon(0% var(--notchTopLeft), var(--notchTopLeft) 0%, calc(100% - var(--notchTopRight)) 0%, 100% var(--notchTopRight), 100% calc(100% - var(--notchBottomLeft)), calc(100% - var(--notchBottomLeft)) 100%, var(--notchBottomRight) 100%, 0% calc(100% - var(--notchBottomRight)));
          clip-path: polygon(0% var(--notchTopLeft), var(--notchTopLeft) 0%, calc(100% - var(--notchTopRight)) 0%, 100% var(--notchTopRight), 100% calc(100% - var(--notchBottomLeft)), calc(100% - var(--notchBottomLeft)) 100%, var(--notchBottomRight) 100%, 0% calc(100% - var(--notchBottomRight)));
  transition: 1s;
}

.offscreen-top {
  transform: translateY(-100%);
}

.top-line {
  height: 1px;
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: #bcd2e8;
}