* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  background-color: #162229;
  color: #bcd2e8;
  font-family: sans-serif;
}

a {
  text-decoration: none;
}